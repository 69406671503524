$(function () {
  toggleOverlay();
  dynamicallyDisplayDistrictDropdown();
});

function toggleOverlay() {
  $('.dropdown-pane').on('show.zf.dropdown', function() {
    $('.region-selector-overlay').show();
    $('.region-selector-overlay').click(function() {
      $('body').removeClass('js-no-scroll');
    });
    $('.dropdown-pane').addClass('region-selector__button--active');
  });

  $('.dropdown-pane').on('hide.zf.dropdown', function() {
    $('.region-selector-overlay').hide();
    $('.dropdown-pane').removeClass('region-selector__button--active');
  });
}

function dynamicallyDisplayDistrictDropdown() {
  var region_selector = '.js-region-selector select';
  var district_selector = '.js-district-selector';

  $(region_selector).change(function() {
    $(district_selector).hide();
    $(district_selector + ' select').attr('disabled', true);

    displaySelectedDistrict(region_selector, district_selector);
  });
}

function displaySelectedDistrict(region_selector, district_selector) {
  var selected_region = $(region_selector + ' :selected').val();

  $(district_selector+'[data-region-name="' + selected_region + '"]').removeClass('hide d-none');
  $(district_selector+'[data-region-name="' + selected_region + '"]').show();
  $(district_selector+'[data-region-name="' + selected_region + '"] select').removeAttr('disabled');
}
