import '../javascripts/region-selector';
import 'trix';

// import Rails from '@rails/ujs'
import $ from 'jquery';

import 'foundation-sites';

window.$ = $;

$(function () {
  // Important: need to load before line 19 so Foundation.Abide object will have those new patterns
  foundationCustomValidation();

  $(document).foundation();
  preventDirectFileUploads();

  foundationAbideFocusOnInvalidInputs();

  $('.js-copy-to-clipboard').click(function() {
    var text = $('.otp_secret_key').text();

    navigator.clipboard.writeText(text).then(function() {
      alert('Key has been copied to clipboard');
    });
  });
});

function preventDirectFileUploads() {
  $('span.trix-button-group--file-tools,button.trix-button--icon-link').remove();
  document.addEventListener('trix-file-accept', function (event) {
    event.preventDefault();
  });
}

function foundationCustomValidation() {
  // using underscore naming for pattern which currenlty Foundation using.
  // https://get.foundation/sites/docs/abide.html#builtin-patterns-and-validators
  Foundation.Abide.defaults.patterns['email_or_website'] = {
    test: function (text) {
      return Foundation.Abide.defaults.patterns['email'].test(text) || Foundation.Abide.defaults.patterns['website'].test(text);
    },
  };

  Foundation.Abide.defaults.patterns['password'] = /.{6,}/;
  Foundation.Abide.defaults.patterns['phone_number'] = /^(?=.*\d)[\d ]+$/;
}

function foundationAbideFocusOnInvalidInputs() {
  $(document).on('forminvalid.zf.abide', function() {
    // Waiting for Foundation Reveal close animation before attempting to focus on the input that has validation errors
    setTimeout(function() {
      $('.is-invalid-input:visible:first').focus();
    }, 100);
  });
}
